<template>
  <div>
    <WsOrder
      :getUrl="`/shop_subclass/${_id}/shop_product/order`"
      :patchUrl="`/shop_subclass/${_id}/shop_product/order`"
    >
      <template #slick-item="item">
        <WsCard>
          <WsInfoTable
            :fields="fields"
            :value="[item.item.item]"
            styleType="bg-transparent"
          ></WsInfoTable>
        </WsCard>
      </template>
    </WsOrder>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        cover_image: {
          type: "image",
          label: this.$t("商品封面圖"),
          previewMaxWidth: "120px",
          previewMaxHeight: "120px",
          uploadable: true,
          linkable: false,
          pocketable: false,
          width: "120px",
        },
        no: {
          type: "text",
          label: this.$t("商品編號"),
          width: "120px",
        },
        name: {
          type: "text",
          label: this.$t("商品名稱"),
          width: "200px",
        },
        spec: {
          type: "text",
          label: this.$t("商品規格"),
          width: "120px",
        },
        cost: {
          type: "number",
          label: this.$t("成本"),
          width: "90px",
        },
        price: {
          type: "number",
          label: this.$t("售價"),
          width: "90px",
        },
        stock_count: {
          type: "number",
          label: this.$t("庫存數量"),
          width: "120px",
        },
      },
    };
  },
  methods: {},
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>